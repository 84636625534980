<template>
  <div>
    <div class="row">
      <div class="col-2 text-right">
        <strong>Собственник</strong>
      </div>
      <div class="col">{{ car.owner }}</div>
    </div>
    <div class="row">
      <div class="col-2 text-right">
        <strong>Марка</strong>
      </div>
      <div class="col">{{ car.mark }}</div>
    </div>
    <div class="row">
      <div class="col-2 text-right">
        <strong>Модель</strong>
      </div>
      <div class="col">{{ car.model }}</div>
    </div>
    <div class="row">
      <div class="col-2 text-right">
        <strong>Гос номер</strong>
      </div>
      <div class="col">{{ car.number }}</div>
    </div>
    <div class="row">
      <div class="col-2 text-right">
        <strong>VIN</strong>
      </div>
      <div class="col">{{ car.vin }}</div>
    </div>
    <div class="row">
      <div class="col-2 text-right">
        <strong>СТС</strong>
      </div>
      <div class="col">{{ car.sts_number }}</div>
    </div>
    <div class="row">
      <div class="col-2 text-right">
        <strong>Форма регистрации</strong>
      </div>
      <div class="col">{{ car.registration_form }}</div>
    </div>
    <div class="row">
      <div class="col-2 text-right">
        <strong>Номер регистрации</strong>
      </div>
      <div class="col">{{ car.registration_number }}</div>
    </div>
    <div class="row">
      <div class="col-2 text-right">
        <strong>Разрешение</strong>
      </div>
      <div class="col">{{ car.resolution }}</div>
    </div>
    <b-button
      @click="onPrintClickHandler"
    >Print</b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { Car } from '@/views/cars/cars_use'

export default {
  components: {
    BButton,
  },
  props: {
    car: {
      type: Car,
      required: true,
    },
  },
  methods: {
    onPrintClickHandler() {
      const config = {
        responseType: 'blob',
      }
      this.$axios.post('/print/driver-contract', {driver_id: 1 }, config).then()
    },
  },
}
</script>
