<template>
  <b-row :cols="cols">
    <b-col>
      <b-form-group
        label="Группа"
        :invalid-feedback="formErrors.group_id"
        :state="!formErrors.group_id"
      >
        <b-form-select
          v-model="formData.group_id"
          :options="groups"
        />
      </b-form-group>
      <b-form-group
        label="Собственник"
        :invalid-feedback="formErrors.owner"
        :state="!formErrors.owner"
      >
        <b-form-input
          v-model="formData.owner"
        />
      </b-form-group>
      <b-form-group
        label="Собственник c даты"
        :invalid-feedback="formErrors.owner_date"
        :state="!formErrors.owner_date"
      >
        <b-form-datepicker
          v-model="formData.owner_date"
          :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
        />
      </b-form-group>
      <b-form-group
        label="VIN"
        :invalid-feedback="formErrors.vin"
        :state="!formErrors.vin"
      >
        <b-form-input
          v-model="formData.vin"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Марка"
        :invalid-feedback="formErrors.mark"
        :state="!formErrors.mark"
      >
        <b-form-input
          v-model="formData.mark"
        />
      </b-form-group>
      <b-form-group
        label="Модель"
        :invalid-feedback="formErrors.model"
        :state="!formErrors.model"
      >
        <b-form-input
          v-model="formData.model"
        />
      </b-form-group>
      <b-form-group
        label="Гос номер"
        :invalid-feedback="formErrors.number"
        :state="!formErrors.number"
      >
        <b-form-input
          v-model="formData.number"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Форма регистрации"
        :invalid-feedback="formErrors.registration_form"
        :state="!formErrors.registration_form"
      >
        <b-form-input
          v-model="formData.registration_form"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Серия и номер регистрации"
        :invalid-feedback="formErrors.registration_number"
        :state="!formErrors.registration_number"
      >
        <b-form-input
          v-model="formData.registration_number"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Св-во о регистрации"
        :invalid-feedback="formErrors.sts_number"
        :state="!formErrors.sts_number"
      >
        <b-form-input
          v-model="formData.sts_number"
        />
      </b-form-group>
      <b-form-group
        label="Разрешение"
        :invalid-feedback="formErrors.resolution"
        :state="!formErrors.resolution"
      >
        <b-form-input
          v-model="formData.resolution"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Трансмиссия"
        :invalid-feedback="formErrors.transmission"
        :state="!formErrors.transmission"
      >
        <b-form-select
          v-model="formData.transmission"
          :options="transmissionOptions"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Газ"
        :invalid-feedback="formErrors.gas_equipment"
        :state="!formErrors.gas_equipment"
      >
        <b-form-select
          v-model="formData.gas_equipment"
          :options="gasOptions"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-checkbox
        v-model="formData.brand"
        class="mb-1"
        :value="1"
        :unchecked-value="0"
        switch
      >Бренд
      </b-form-checkbox>
      <b-form-checkbox
        v-model="formData.hide_crm"
        class="mb-1"
        :value="1"
        :unchecked-value="0"
        switch
      >Скрывать в CRM
      </b-form-checkbox>
      <b-form-checkbox
        v-model="formData.hide_driver"
        class="mb-1"
        :value="1"
        :unchecked-value="0"
        switch
      >Скрывать у водителей
      </b-form-checkbox>
    </b-col>
    <b-col cols="12">
      <hr/>
      <div class="d-flex justify-content-end flex-grow-1 mb-2 mt-2">
        <b-button
          @click="resetFormData"
        >Сбросить
        </b-button>
        <b-button
          class="ml-2"
          variant="primary"
          @click="saveHandler"
        >Сохранить
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
} from 'bootstrap-vue'
import { formData2FormErrors } from '@/libs/utils'
import { Car, GAS_LABELS, TRANSMISSION_LABELS } from '../cars_use'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormDatepicker,
    BFormSelect,
    BFormCheckbox,
    BButton,
  },
  props: {
    car: {
      type: Car,
      default() {
        return new Car()
      },
    },
    cols: {
      type: Number,
      default() {
        return 1
      },
    },
  },
  data() {
    return {
      formData: null,
      formErrors: null,
      groups: [{
        value: null,
        text: 'Нет',
      }],
    }
  },
  created() {
    this.resetFormData()
  },
  computed: {
    transmissionOptions() {
      return Array.from(Object.keys(TRANSMISSION_LABELS), key => ({
        value: key,
        text: TRANSMISSION_LABELS[key],
      }))
    },
    gasOptions() {
      const opts = Array.from(Object.keys(GAS_LABELS), key => ({
        value: key,
        text: GAS_LABELS[key],
      }))
      opts.push({
        value: null,
        text: 'Нет',
      })
      return opts
    },
  },
  mounted() {
    this.fetchGroups()
  },
  methods: {
    fetchGroups() {
      this.$axios.get('/cars/groups')
        .then(response => {
          response.data.data.forEach(item => {
            this.groups.push({
              value: item.id,
              text: item.title,
            })
          })
        })
    },
    saveHandler() {
      this.resetFormErrors()
      this.$axios[this.car.id ? 'put' : 'post'](`/cars${this.car.id ? `/${this.car.id}` : ''}`, this.formData)
        .then(response => {
          this.$emit('saved', response.data.data)
        })
        .catch(error => {
          // eslint-disable-next-line no-restricted-syntax
          for (const [field, messages] of Object.entries(error.response.data.errors)) {
            if (field in this.formErrors) {
              this.formErrors[field] = messages.join('\n')
            }
          }
        })
    },
    resetFormErrors() {
      this.formErrors = formData2FormErrors(this.formData)
    },
    resetFormData() {
      this.formData = { ...this.car }
      this.resetFormErrors()
    },
  },
}
</script>
