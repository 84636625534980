<template>
  <div>
    <div
      v-for="(item, index) in value"
      :key="index"
      class="row mb-1"
    >
      <div class="col-10">
        <b-form-input
          :value="item"
          @input="v => onInputHandler(v, index)"
        />
      </div>
      <div class="col-2">
        <b-button
          v-if="index + 1 < value.length"
          variant="outline primary"
          @click="onDeleteHandler(index)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BFormInput,
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    onInputHandler(value, index) {
      this.$emit('input', this.value.map((item, i) => (i === index ? value : item)))
    },
    onDeleteHandler(index) {
      this.$emit('input', this.value.filter((item, i) => i !== index))
    },
  },
}
</script>
