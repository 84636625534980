var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.car.owner))])]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.car.mark))])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.car.model))])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.car.number))])]),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.car.vin))])]),_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.car.sts_number))])]),_c('div',{staticClass:"row"},[_vm._m(6),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.car.registration_form))])]),_c('div',{staticClass:"row"},[_vm._m(7),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.car.registration_number))])]),_c('div',{staticClass:"row"},[_vm._m(8),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.car.resolution))])]),_c('b-button',{on:{"click":_vm.onPrintClickHandler}},[_vm._v("Print")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('strong',[_vm._v("Собственник")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('strong',[_vm._v("Марка")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('strong',[_vm._v("Модель")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('strong',[_vm._v("Гос номер")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('strong',[_vm._v("VIN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('strong',[_vm._v("СТС")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('strong',[_vm._v("Форма регистрации")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('strong',[_vm._v("Номер регистрации")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('strong',[_vm._v("Разрешение")])])
}]

export { render, staticRenderFns }