<template>
  <div>
    <v-table
      ref="table"
      path="/cars/insurances"
      :filter="tableFilter"
      :fields="tableFields"
      @create="createHandler"
    >
      <template #cell(serial)="data">
        <v-table-serial-column
          :data="data"
        />
      </template>
      <template #cell(actions)="data">
        <v-table-actions-column
          :view="false"
          @delete="deleteHandler(data.item)"
          @edit="editHandler(data.item)"
        />
      </template>
    </v-table>
    <b-modal
      ref="form-modal"
      :title="`${formData.id ? 'Редактирование' : 'Добавление'} страховки`"
      @ok.prevent="saveFormData"
    >
      <b-overlay
        :show="formDataSaving"
      >
        <b-form-group label="Серия и номер">
          <b-form-input
            v-model="formData.number"
          />
        </b-form-group>
        <b-form-group label="Компания">
          <b-form-input
            v-model="formData.company_name"
          />
        </b-form-group>
        <div class="row mb-1">
          <div class="col-12">
            <legend class="bv-no-focus-ring col-form-label pt-0">
              Даты
            </legend>
          </div>
          <div class="col-6">
            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  v-model="formData.date_start"
                  :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
                  button-only
                  size="sm"
                  left
                />
              </b-input-group-prepend>
              <b-form-input v-model="formData.date_start" />
            </b-input-group>
          </div>
          <div class="col-6">
            <b-input-group>
              <b-form-input v-model="formData.date_end" />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="formData.date_end"
                  :date-format-options="{day: '2-digit', month: '2-digit', year: 'numeric'}"
                  locale="ch"
                  button-only
                  size="sm"
                  right
                />
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-form-group
          label="Статус"
        >
          <b-form-select
            v-model="formData.status"
            :options="statusOptions"
          />
        </b-form-group>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormDatepicker, BFormGroup, BFormInput, BFormSelect, BModal, BOverlay,
  BInputGroup, BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'
import VTable, { VTableActionsColumn, VTableSerialColumn } from '@/views/components/vtable'
import { INSURANCE_LABELS } from '@/views/cars/cars_use'
import { dateFormat } from '@/libs/filters'

const emptyFormData = {
  id: null,
  number: null,
  company_name: null,
  date_start: null,
  date_end: null,
  status: 'NONE',
}

export default {
  components: {
    VTable,
    VTableActionsColumn,
    VTableSerialColumn,
    BModal,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormSelect,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
  },
  props: {
    car_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dateStart: null,
      formData: null,
      formDataSaving: false,
      tableFields: [
        {
          key: 'serial',
          label: '#',
          sortable: true,
        },
        {
          key: 'number',
          label: 'Серия и номер',
          sortable: true,
        },
        {
          key: 'company_name',
          label: 'Компания',
          sortable: true,
        },
        {
          key: 'date_start',
          label: 'Действительна',
          sortable: true,
          formatter(start, _, item) {
            return `${dateFormat(start, true, false)} / ${dateFormat(item.date_end, true, false)}`
          },
        },
        {
          key: 'status',
          label: 'Статус',
          formatter(status) {
            return INSURANCE_LABELS[status]
          },
        },
        {
          key: 'created_by',
          label: 'Добавил',
          formatter(creator, _, item) {
            return `${creator} (${dateFormat(item.created_at)})`
          },
        },
        {
          key: 'actions',
          label: '',
        },
      ],
    }
  },
  computed: {
    tableFilter() {
      return {
        car_id: this.car_id,
      }
    },
    statusOptions() {
      return Array.from(Object.keys(INSURANCE_LABELS),
        key => ({
          value: key,
          text: INSURANCE_LABELS[key],
        }))
    },
  },
  created() {
    this.resetFormData()
  },
  methods: {
    resetFormData() {
      this.formData = { ...emptyFormData }
    },
    createHandler() {
      this.resetFormData()
      this.$refs['form-modal'].show()
    },
    editHandler(obj) {
      this.formData = { ...obj }
      this.$refs['form-modal'].show()
    },
    deleteHandler(obj) {
      this.$axios.delete(`/cars/insurances/${obj.id}`)
        .then(() => this.$refs.table.refresh())
    },
    saveFormData() {
      this.formDataSaving = true
      this.$axios[this.formData.id ? 'put' : 'post'](`/cars/insurances${this.formData.id ? `/${this.formData.id}` : ''}`,
        {
          ...this.formData,
          car_id: this.car_id,
        })
        .then(() => {
          this.$refs.table.refresh()
          this.$refs['form-modal'].hide()
        })
        .finally(() => {
          this.formDataSaving = false
        })
    },
  },
}
</script>
