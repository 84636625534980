<template>
  <b-card v-if="car">
    <h1>{{ car.title() }}</h1>
    <b-tabs>
      <b-tab title="Основная информация">
        <car-view :car="car" />
      </b-tab>
      <b-tab title="Страховки ОСАГО">
        <car-insurance :car_id="car.id" />
      </b-tab>
      <b-tab title="Техосмотры">
        <car-tech-inspect :car_id="car.id" />
      </b-tab>
      <b-tab title="Обслуживание ТС">
        <car-service :car_id="car.id" />
      </b-tab>
      <b-tab title="История изменений">
        <car-history :car_id="car.id" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="EditIcon" />
        </template>
        <car-form
          :car="car"
          :cols="2"
          @input="onInputHandler"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import { Car } from '@/views/cars/cars_use'
import CarView from '@/views/cars/components/CarView.vue'
import CarInsurance from '@/views/cars/components/CarInsurance.vue'
import CarTechInspect from '@/views/cars/components/CarTechInspect.vue'
import CarService from '@/views/cars/components/CarService.vue'
import CarForm from '@/views/cars/components/CarForm.vue'
import CarHistory from '@/views/cars/components/CarHistory.vue'

export default {
  components: {
    CarForm,
    CarService,
    CarTechInspect,
    CarInsurance,
    CarHistory,
    BCard,
    CarView,
    BTabs,
    BTab,
  },
  data() {
    return {
      page: 'car',
      car: null,
    }
  },
  mounted() {
    this.fetchCar()
  },
  methods: {
    fetchCar() {
      this.$axios.get(`/cars/${this.$route.params.id}`)
        .then(response => {
          this.car = new Car(response.data.data)
        })
    },
    onInputHandler(data) {
      this.car = new Car(data)
    },
  },
}
</script>
