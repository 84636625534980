<template>
  <div>
    <v-table
      path="/cars/history"
      :filter="tableFilter"
      :fields="tableFields"
    >
      <template #cell(serial)="data">
        <v-table-serial-column :data="data" />
      </template>
    </v-table>
  </div>
</template>

<script>
import VTable, {
  VTableSerialColumn,
} from '@/views/components/vtable'

export default {
  components: { VTableSerialColumn, VTable },
  props: {
    car_id: {
      type: Number,
      required: true,
    },
  },
  component: {
    VTable, VTableSerialColumn,
  },
  computed: {
    tableFilter() {
      return {
        car_id: this.car_id,
      }
    },
  },
  data() {
    return {
      tableFields: [
        {
          key: 'serial',
          label: '',
        },
        {
          key: 'user_title',
          label: 'Пользователь',
        },
        {
          key: 'date',
          label: 'Дата',
        },
        {
          key: 'description',
          label: 'Описание',
        },
      ],
    }
  },
}
</script>
