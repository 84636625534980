<template>
  <div>
    <v-table
      ref="table"
      path="/cars/tech-inspects"
      :filter="tableFilter"
      :fields="tableFields"
      sort="-date_start"
      @create="createHandler"
    >
      <template #cell(serial)="data">
        <v-table-serial-column
          :data="data"
        />
      </template>
      <template #cell(actions)="data">
        <v-table-actions-column
          :view="false"
          @delete="deleteHandler(data.item)"
          @edit="editHandler(data.item)"
        />
      </template>
    </v-table>
    <b-modal
      ref="form-modal"
      :title="`${formData.id ? 'Редактирование' : 'Добавление'} техосмотра`"
      @ok.prevent="saveFormData"
    >
      <b-form-group label="Серия и номер">
        <b-form-input
          v-model="formData.number"
        />
      </b-form-group>
      <b-form-group label="Пробег">
        <b-form-input
          v-model="formData.mileage"
        />
      </b-form-group>
      <div class="row">
        <div class="col-12">
          <label>Даты</label>
        </div>
        <div class="col-6">
          <b-form-datepicker
            v-model="formData.date_start"
            :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
          />
        </div>
        <div class="col-6">
          <b-form-datepicker
            v-model="formData.date_end"
            :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormDatepicker, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'
import VTable, { VTableActionsColumn, VTableSerialColumn } from '@/views/components/vtable'
import { INSURANCE_LABELS } from '@/views/cars/cars_use'
import { dateFormat } from '@/libs/filters'

const emptyFormData = {
  id: null,
  number: null,
  date_start: null,
  date_end: null,
  mileage: 0,
}

function getEmptyFormData() {
  return { ...emptyFormData }
}

export default {
  components: {
    VTable,
    VTableActionsColumn,
    VTableSerialColumn,
    BModal,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
  },
  props: {
    car_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dateStart: null,
      formData: getEmptyFormData(),
      tableFields: [
        {
          key: 'serial',
          label: '#',
        },
        {
          key: 'number',
          label: 'Серия и номер',
        },
        {
          key: 'mileage',
          label: 'Пробег',
        },
        {
          key: 'date_start',
          label: 'Действителен',
          formatter(start, _, item) {
            return `${dateFormat(start, true, false)} / ${dateFormat(item.date_end, true, false)}`
          },
        },
        {
          key: 'created_by',
          label: 'Добавил',
          formatter(creator, _, item) {
            return `${creator} (${dateFormat(item.created_at)})`
          },
        },
        {
          key: 'actions',
          label: '',
        },
      ],
    }
  },
  computed: {
    tableFilter() {
      return { car_id: this.car_id }
    },
    statusOptions() {
      return Array.from(Object.keys(INSURANCE_LABELS),
        key => ({
          value: key,
          text: INSURANCE_LABELS[key],
        }))
    },
  },
  methods: {
    createHandler() {
      this.formData = getEmptyFormData()
      this.$refs['form-modal'].show()
    },
    editHandler(obj) {
      this.formData = { ...obj }
      this.$refs['form-modal'].show()
    },
    deleteHandler(obj) {
      this.$axios.delete(`/cars/tech-inspects/${obj.id}`)
        .then(() => this.$refs.table.refresh())
    },
    saveFormData() {
      this.$axios[this.formData.id ? 'put' : 'post'](`/cars/tech-inspects${this.formData.id ? `/${this.formData.id}` : ''}`,
        {
          ...this.formData,
          car_id: this.car_id,
        })
        .then(() => {
          this.$refs.table.refresh()
          this.$refs['form-modal'].hide()
        })
    },
  },
}
</script>
